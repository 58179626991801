import React, { useEffect, useRef } from 'react'
import { motion, useAnimation } from 'framer-motion'

const FrameMotion = ({ children, ...props }) => {
    const controls = useAnimation();

    // useEffect(() => {
    //     if (inView) {
    //         controls.start("visible");
    //     }
    // }, [controls, inView]);

    return (
        <motion.div

            {...props}
        >
            {children}
        </motion.div>
    )
}

export default FrameMotion