import images from './images'
export const features = [
    {
        name: 'Easy Access',
        description: 'Download the app, create an account, confirm your email address and you\'re ready to go. If you wish, create a profile where you can store your favorite videos and personalize your account. Contact our teachers for further suggestions.'
    },
    {
        name: 'Enjoyable Content',
        description: 'Small learning units as well as beginners and child-friendly animations make it easy to get started, especially at the beginning of the learning process. Memorize content by watching the videos and listening to the audio repeatedly; thus, experience quick results.'
    },
    {
        name: 'User-friendly',
        description: 'We do not send advertising or sell personal data. The protection of children is important to us and is taken seriously. We will delete unused access data automatically after one week. If you have enjoyed our program, subscribe for the new content we will be adding soon.'
    }
];

export const socialMedia = [
    {
        name: 'Facebook',
        type:'fb',
        link: '#0',
        status:0
    },
    {
        name: 'Instagram',
        type:'insta',
        link: 'https://www.instagram.com/englishAminute/',
        status:1
    },
];

export const teachersData = [
    {
        name: 'Gudrun Manz',
        image: images.img_4,
        desk:3,
        mobile:1,
        designation: 'CEO / English tutor',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, ex quaerat laboriosam consequatur repellat corporis, accusantium explicabo, dolores odit iste maiores! Et possimus sunt atque fuga amet ratione accusantium repudiandae.',
    },
    {
        name: 'Sibylle Hoffmann',
        image: images.img_2,
        desk:2,
        mobile:2,
        designation: 'English Teacher',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, ex quaerat laboriosam consequatur repellat corporis, accusantium explicabo, dolores odit iste maiores! Et possimus sunt atque fuga amet ratione accusantium repudiandae.',
    },
    {
        name: 'Susanta Poddar',
        image: images.img_5,
        desk:1,
        mobile:3,
        designation: 'English Instructor',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, ex quaerat laboriosam consequatur repellat corporis, accusantium explicabo, dolores odit iste maiores! Et possimus sunt atque fuga amet ratione accusantium repudiandae.',
    },
    {
        name: 'Gloria Govender',
        image: images.img_3,
        desk:4,
        mobile:4,
        designation: 'English Teacher',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, ex quaerat laboriosam consequatur repellat corporis, accusantium explicabo, dolores odit iste maiores! Et possimus sunt atque fuga amet ratione accusantium repudiandae.',
    },
    {
        name: 'Robin',
        image: images.img_1,
        desk:5,
        mobile:5,
        designation: 'English Instructor',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, ex quaerat laboriosam consequatur repellat corporis, accusantium explicabo, dolores odit iste maiores! Et possimus sunt atque fuga amet ratione accusantium repudiandae.',
    },
];