import React from 'react'
import { Button } from 'react-bootstrap';
import { HomePage } from './pages';

const App = () => {
    return (
        <>
            <HomePage />
        </>
    )
}

export default App