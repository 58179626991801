import React from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import images from "../constants/images";
import { DiAndroid, DiApple } from "react-icons/di";
import { features, socialMedia } from "../constants/data";
import FrameMotion from "../components/FrameMotion";
import { motion, LazyMotion, domAnimation } from "framer-motion";
import Teachers from "../components/Teachers";
import { FiInstagram } from "react-icons/fi";

const HomePage = () => {
  const widthWindow = window.innerWidth;

  const whenChildrent = {
    duration: 1,
    ease: "easeInOut",
    when: "beforeChildren",
    staggerChildren: 0.5,
  };

  const sliedImage = {
    visible: {
      x: widthWindow > 580 ? 100 : 0,
      transition: whenChildrent,
    },
    hidden: {
      x: 1000,
    },
  };

  const sliedText = {
    visible: {
      x: 0,
      transition: whenChildrent,
    },
    hidden: {
      x: -1000,
    },
  };
  return (
    <>
      <section>
        <div
          className="slider-container"
          style={{
            backgroundImage: `url(${images.banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container">
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                className="col-sm-6 text-white justify-content-center"
              >
                <FrameMotion
                  variants={sliedText}
                  animate="visible"
                  initial="hidden"
                >
                  <h1>
                    <strong>Learn English Online</strong>
                  </h1>
                  <p style={{ fontWeight: "500", textTransform: "capitalize" }}>
                    3D-animated videos will guide you through your first
                    language experience
                  </p>
                  <div className="banner-btn-container">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.endlishAminute"
                    >
                      <div className="banner-btn b-btn-1">
                        <DiAndroid
                          size={28}
                          style={{
                            marginRight: "10px",
                          }}
                        />
                        <div className="btn-item">
                          <div style={{ fontSize: "0.7rem" }}>
                            Available on
                          </div>
                          <div>
                            <strong>Google Play</strong>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a
                      target="_blank"
                      href="https://apps.apple.com/in/app/english-a-minute/id1596428632"
                    >
                      <div className="banner-btn b-btn-2">
                        <DiApple
                          size={28}
                          style={{
                            marginRight: "10px",
                          }}
                        />
                        <div className="btn-item">
                          <div style={{ fontSize: "0.7rem" }}>
                            Available in the 
                          </div>
                          <div>
                            <strong>App Store</strong>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </FrameMotion>
              </div>

              <div className="col-sm-6 text-center">
                <FrameMotion
                  variants={sliedImage}
                  animate="visible"
                  initial="hidden"
                >
                  <motion.img
                    animate={{
                      y: 10,
                      transition: {
                        yoyo: Infinity,
                        duration: 1,
                      },
                    }}
                    src={images.ScreenShot1}
                    style={{
                      height: "80vh",
                    }}
                  />
                </FrameMotion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 container-bg">
        <div className="section3">
          <div className="container feature-container">
            <LazyMotion features={domAnimation}>
              <motion.div animate={{ opacity: 1 }} className="row">
                <div className="col-sm-12 text-center">
                  <h2 className="mb-4 sectionTitle">App Features</h2>
                </div>
                {features.map((feature, index) => {
                  return (
                    <div className="col-sm-4 mt-2 mb-2" key={index}>
                      <div className="feature-item text-center">
                        <h5>{feature.name}</h5>
                        <p>{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </motion.div>
            </LazyMotion>
          </div>
        </div>
      </section>

      <section className="p-5">
        <div className="section2">
          <div className="container">
            <div className="row">
              <FrameMotion
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                initial={{
                  opacity: 0,
                  y: 10,
                }}
                transition={{
                  duration: 1,
                  delay: 0.5,
                }}
              >
                <div className="col-sm-12 text-center">
                  <h2 className="sectionTitle">About Us</h2>
                </div>
              </FrameMotion>
              <div className="col-sm-6 text-start">
                <FrameMotion
                  whileInView={{
                    opacity: 1,
                    y: 0,
                  }}
                  initial={{
                    opacity: 0,
                    y: "100%",
                  }}
                  transition={{
                    duration: 1,
                    delay: 0.5,
                  }}
                >
                  <h3>EnglishAMinute.com</h3>
                  <p>
                    English a minute is dedicated to young learners or absolute
                    beginners. 3D-animated videos prepare kids for school or
                    guide newbies. The content can quickly be memorized and
                    explains itself. You prefer personal tutoring? Passionate
                    teachers will accompany you. Enjoy your learning journey!
                  </p>
                </FrameMotion>
              </div>
              <div className="col-sm-6 about-img text-end">
                <FrameMotion
                  animate={{
                    opacity: 1,
                    y: 0,
                    whileInView: {
                      opacity: 1,
                    },
                  }}
                  initial={{
                    opacity: 0,
                    y: "100%",
                  }}
                  transition={{
                    duration: 1,
                    delay: 0.5,
                  }}
                >
                  <img src={images.AboutUs} />
                </FrameMotion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 container-bg-2">
        <div className="section3">
          <div className="container feature-container">
            <LazyMotion features={domAnimation}>
              <motion.div animate={{ opacity: 1 }} className="row">
                <div className="col-sm-12 text-center mb-5">
                  <h2 className="sectionTitle">Our teachers</h2>
                  <p>
                    Meet our our most experienced and professional teachers.
                  </p>
                </div>
                <div className="col-sm-12 mb-4">
                  <Teachers />
                </div>
              </motion.div>
            </LazyMotion>
          </div>
        </div>
      </section>

      <section className="p-5">
        <div className="section2">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center"></div>
              <div className="col-sm-6 text-start">
                <div className="contact-box">
                  <h2 className="sectionTitle">Contact Us</h2>
                  <div>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Full Name <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="eg. John Watson"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Email address <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="name@example.com"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="eg. +49123465790"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>
                          Message <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Write Something..."
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 text-end"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Button className="btn-gradient btn-md">Submit</Button>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 about-img text-end">
                <img
                  src={images.contact}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 section4">
        <div>
          <div className="container subscription-container">
            <div className="row">
              <div className="col-sm-12 text-center mb-4">
                <h2 className="sectionTitle">Subscribe For New Features</h2>
                <h6>
                  Be with the first to see additional content! Enjoy new videos,
                  explore the world <br />
                  and the English language with Ben, Amy, Noah and Mia.
                </h6>
              </div>
              <div className="col-sm-3 text-center mb-4"></div>
              <div className="col-sm-6 text-center mb-4">
                <div className="subscription-div">
                  <InputGroup>
                    <FormControl
                      placeholder="Enter Email Address"
                      aria-label="Enter Email Address"
                      aria-describedby="basic-addon2"
                    />
                    <button>Subscribe</button>
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer
        style={{
          backgroundColor: "#000000",
          padding: "1rem",
          color: "#fff",
        }}
        className="text-center"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6 text-start">
              {socialMedia.map((item, index) => {
                if (item.status) {
                  return (
                    <a target={"_blank"} href={item.link} key={index}>
                      <FiInstagram color="#fff" size={20} />
                    </a>
                  );
                }
              })}
            </div>
            <div className="col-sm-6 text-end">
              Copyright © 2022 EnglishAMinute. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomePage;
