import banner from './../assets/images/banner.png'
import contact from './../assets/images/contact.png'
import ScreenShot1 from './../assets/images/Screen-Shot1.png'
import ScreenShot2 from './../assets/images/Screen-Shot2.png'
import ScreenShot3 from './../assets/images/Screen-Shot3.png'
import ScreenShot4 from './../assets/images/Screen-Shot4.png'
import ScreenShot5 from './../assets/images/Screen-Shot5.png'
import AboutUs from './../assets/images/about-us.jpg'

import img_1 from './../assets/images/1.png'
import img_2 from './../assets/images/2.png'
import img_3 from './../assets/images/3.png'
import img_4 from './../assets/images/4.png'
import img_5 from './../assets/images/5.png'


export default {
    banner,
    contact,
    ScreenShot1,
    ScreenShot2,
    ScreenShot3,
    ScreenShot4,
    ScreenShot5,
    AboutUs,
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    
}