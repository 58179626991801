import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { teachersData } from '../constants/data';



const Teachers = () => {


    const [SlidesPerView, setSlidesPerView] = useState(5);
    const [TeachersList, setTeachersList] = useState(teachersData);

    const sliderViewFunction = () =>{
        if (window.innerWidth < 480) {
            setSlidesPerView(1)
          } else 
          if (window.innerWidth > 480 && window.innerWidth < 650) {
            setSlidesPerView(2)
          } else 
          if (window.innerWidth < 650) {
            setSlidesPerView(3)
          } else 
          if (window.innerWidth < 992) {
            setSlidesPerView(3)
          } else 
          {
            setSlidesPerView(5)
          }
    }
    useEffect(() => {
        sliderViewFunction()
      window.addEventListener('resize', () => {
       sliderViewFunction()
      })
    }, [])
    


    return (
        <>
            <Swiper
                slidesPerView={SlidesPerView}
                spaceBetween={30}
                slidesPerGroup={1}
                loop={false}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {
                    TeachersList.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="teacher-item">
                                    <div className="teacher-item-img">
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className="teacher-item-name mt-4">
                                        <h6 className='mb-0' style={{ fontWeight:'700' }}>{item.name}</h6>
                                        <p style={{ fontSize:12, fontWeight:'600' }}><i>{item.designation}</i></p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    )
}

export default Teachers